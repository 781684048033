// Post4.js

import Post4Summary from "../summary/Post4Summary";

const Post4 = (lang) => {
	let content
	if (lang === 'rom') {
		const romText = `
# Fața de masă

Aceasta este povestea dramatica a mătușii mele (stră-mătușa?), tanti Flora, și a felului în care viata ei s-a intersectat cu viața mamei, bunicii, străbunicii (și chiar a mea).
<figure>
<img src="./post4/floraMiddleAge.png"
	style="display: block; margin: auto; width: 300px" />
	<figcaption style="text-align: center;" ><i>Flora în 1943</i></figcaption>
</figure>

Flora a fost sora mai mică a străbunicii mele, Constanța (povestea Constanței [aici](./litere)) și, în termeni moderni, Flora a fost cea mai moderna si aventuroasa, cel puțin până când a lovit tragedia. 
Nu i-am anglicizat numele, ea era cunoscută sub numele de Flora, deși probabil a fost botezată româneste, Floarea.

Păstrând proporțiile sociale, dar pentru a folosi o comparație cu familia regala britanică, cred ca Flora a fost ca prințesa Margareta și Constanța ca prințesa Elisabeta în sensul că sora cea mare a avut tot timpul responsabilitati si datorii si cea mica si-a permis sa fie mai aventuroasa si mai libera.
Constanța avea o mulțime de responsabilități, intii ca sora mai mare a doi băieți și două fete, îngrijind de cei mai mici. 
Apoi, Constanța a fost o soție tânără, apoi văduvă și mamă. 
Cu mai puține responsabilități și mai libera, viața Florei a fost diferită. Relațiile dintre cele două surori par să fi fost calde și au fost apropiate de-a lungul vieții cu toate diferențele dintre ele.

Se pare ca a fost o fată foarte isteață care și-a convins cumva părinții să o lase să plece la București la sfârșitul anilor 1920 să se pregateasca și, mai târziu, să lucreze ca moașă. 

Singurul meu punct de referință pentru moașă este serialul BBC, „Call the midwife” (Chema moasa), care are loc (prima serie) într-un cartier mai saracut din Londra in anii '50. Este aceasta o bună aproximare a vieții moașei din anii 20 și 30 din București? Bebelușii sunt bebeluși, nu?
Flora și-a convins părinții sau a mers împotriva dorințelor lor? A plecat singură? Cum s-a pregatit? Părinții au susținut-o financiar? Unde lucra ea? A fost atașată la un spital sau a ajutat femeile să nască în casele lor? Cum ajungea la femeile in travalia? A folosit o bicicletă ca moașele din serialul TV? Mergea pe jos? Avea o geantă de moașă, așa cum aveau la televizor? Câți copii a ajutat sa se născa? De ce nu era într-o fotografie de familie, ci și-a luat o fotografie proprie? Era un pic rebelă?
Aș avea un milion de întrebări pe care să-i le pun Florei dacă ar fi posibil.
<figure>
<img src="./post4/floraYoung.png"
	style="display: block; margin: auto; width: 300px" />
	<figcaption style="text-align: center;" ><i>Flora ca o fată</i></figcaption>
</figure>
Oricum, Flora a trăit viața unei femei am zice de cariera, în luminile strălucitoare ale Bucureștiului interbelic pe atunci cunoscut sub numele de micul Paris.

În anii ‘30, Flora închiria un apartament în centrul orașului, lângă Liceul Lazăr. Mamei mele îi place să găsească paralele in timp și acesta este liceul pe care l-am urmat mai târziu. Obișnuiam să-mi dau ochii peste cap la asta, în zilele noastre mai puțin 🙂.

Flora a fost acolo în București când tânăra mea bunica, Aurelia, s-a mutat la București, de îndată ce a împlinit 18 ani. 
A fost mătușa ei o inspirație? Cu toții avem nevoie de modelele noastre de urmat.
Aurelia a locuit cu mătușa ei și erau foarte apropiate, pe acceasi lungime de unda. 
Flora pare să fi fost un amestec de soră mai mare și mamă  pentru Aurelia.

Cei aproape trei ani pe care i-au avut la București au fost, probabil, cei mai fericiți din viața lor de adult pentru amândoua. 
Aveau amindoua meseriile lor importante(Aurelia se zice ca a fost secretara de ministru si apoi prim-ministru), făceau cumpărături, se întâlneau cu prieteni și rude în vizită in oras, se relaxau în parcul central al Bucureștiului, Cișmigiu, cu o carte. 
Când a venit Constanța la București, a stat evident cu sora si fiica ei, așa că au la un moment dat au fost toate trei vizavi de vechiul meu liceu.
<figure>
<img src="./post4/cismigiu.png"
	style="display: block; margin: auto; width: 300px" />
	<figcaption style="text-align: center;" ><i>Flora și Aurelia în parc</i></figcaption>
</figure>
Din pacate, începutul celui de-al doilea război mondial a pus capăt acestei situatii. Flora s-a mutat și ea înapoi la țară, într-un alt sat din același județ, Argeș, și a continuat să lucreze ca moașă. S-a vorbit despre un draguț în tinerețe la București, dar Flora nu s-a căsătorit niciodată și nici nu a avut copii.

Aurelia era însărcinată în a doua jumătate a anului 1945 și Flora a fost moașa ei în acea zi fatidica de decembrie. 
Iarna a fost groaznică în acel an, conform câteva surse pe care le-am putut găsi. Era și sfârșitul războiului, trupele sovietice peste tot. A fost alegerea Aureliei să nască acasă sau pur și simplu nu a existat altă opțiune? 
Era normal să nască acasă pentru toate clasele sociale? A existat o încercare de a o duce pe Aurelia la spital? 
Au fost drumurile inutilizabile din cauza zăpezii? Aurelia s-a simțit mai bine cu mătușa ei? 
Prea traumatizante, de aceste detalii nu s-a vorbit niciodată.<br>

Flora era o moașă cu multa experienta in 1945, dar lucrurile au luat o intorsatura tragica și Aurelia a murit. Flora a fost devastată, îndurerată și de neconsolat. „S-a rupt ceva in Flora atunci”, Constanța a descris mai târziu momentul.
Știm acum că medicii nu trebuie să trateze, să opereze membrii familiei. A fost la fel atunci și acolo? A existat o alegere?
 Mama spune că aceasta este singura dată când o femeie însărcinată a murit în grija Florei. Imposibil de confirmat, dar groaznic oricum.
Flora a continuat să lucreze ca moașă după aceasta. Probabil că nu a avut de ales cu toată averea familiei confiscată după război.

Mama mea, Mariana, își amintește de Flora ca fiind o femeie foarte bătrână în doliu, arătând mai în vârstă decât Constanța. Mariana descrie o scenă în care Flora i-a făcut cadou cu umilință o față de masă încrețită și sifonată. Micuța Mariana nu avea nevoie de o față de masă și mai târziu i-a spus  Constanței că este urâta și că nu-o vrea, dar Constanța a spus că aceasta este una specială și că trebuie să aibă grijă de ea. 
Mariana a avut grija de față de masă și zeci de ani mai târziu. Biata Flora a pus tot sufletul în a broda această față de masă. 
Sper că a găsit puțină mângâiere și s-a simțit aproape de draga ei Aurelia.

Fața asta de masă nu mi-a plăcut în copilărie, era prea incarcată, prea demodată, îmi doream ceva simplu și colorat sau fără față de masă deloc. 
Abia acum apreciez ce inseamna asta.
În ultimele 18 luni, fața de masă a fost în sufrageria noastră, cu îngeri brodați cu tot. Fața de masă cam aceeași vârstă cu casa noastră. 
Este acolo pentru toate mesele noastre, o legătură tangibilă cu aceste două femei remarcabile.
<figure>
<img src="./post4/tablecloth.png"
	style="display: block; margin: auto; width: 300px" />
	<figcaption style="text-align: center;" ><i>Fața de masă</i></figcaption>
</figure>
Îmi place să-mi imaginez că există un univers paralel undeva în care timpul s-a oprit pentru totdeauna la anii 1930 în București și Aurelia și Flora sunt în parc, râzând și citind cărți.

				
`
		content = romText
	} else { 
const postText = `
# The tablecloth 

This is the story of my great-great aunt, tanti Flora, and the way it intersected with my mother’, grandmother’, great-grandmother’ lives(and even my own).
<figure>
<img src="./post4/floraMiddleAge.png"
     style="display: block; margin: auto; width: 300px" />
     <figcaption style="text-align: center;" ><i>Flora in 1943</i></figcaption>
</figure>

Flora was the younger sister of my great-grandmother, Constance (Constance’ story [here](./#letters)) and, in modern terms, Flora was the cool one, at least until tragedy struck. I didn’t anglicise her name, she was known as Flora, though probably baptised the more Romanian, Floarea.

Keeping the social proportions, but to use an English royalty comparation, I think as Flora as princess Margaret to Constance’s princess Elizabeth, in the sense that Constance had lots of responsibilities as, first, the elder sister of two boys and two girls, no doubt doing a lot of looking after younger siblings. And then, Constance was a young wife, then widow and mother. With less responsibilities and freer, Flora’s life was different. The relationships between the two sisters appear to have been warm and they were close throughout their lives for all their differences. 
I’d have a million questions to ask Flora if it was possible. 

By all accounts, she was a very spirited, clever girl who somehow convinced her parents to let her go to Bucharest in late 1920s to train and, later, work as a midwife. <br>
My only reference point for midwifery is the BBC series, “Call the midwife”, set (the first series) in ‘50s East End. Is this a good approximation of 20s and 30s midwife’s life in Bucharest? Babies are babies, right? 
Did Flora convince her parents or did she go against their wishes? Did she  go alone? How did she train? Did the parents support her financially? Where did she work? Was she attached to a hospital but helping women give birth in their homes? How would she get to the labouring women? Was she using a bicycle like the midwives in the TV series? Was she walking? Were horses involved? Public transport? Did she have a midwife bag like they had on TV? How many babies did she deliver? Why was she not in a family photo but got a photo of her own? Was she a bit of a rebel?
<figure>
<img src="./post4/floraYoung.png"
     style="display: block; margin: auto; width: 300px" />
     <figcaption style="text-align: center;" ><i>Flora as a girl</i></figcaption>
</figure>
Anyway, Flora lived the life of a single, busy woman in the bright lights of the booming interbelic Bucharest, then known as little Paris with art deco buildings popping everywhere and the oil money pouring in.

In the ‘30s, Flora was renting a flat in the center of the town, next to Lazar High School. My mother loves to find patterns across the time and this is the high school I later attended. I used to roll my eyes at this, nowadays less so 🙂. 

Flora was there in Bucharest when my young grandmother, Aurelia, made her own move to Bucharest as soon as she turned 18. Could her aunt have been an inspiration? We all need our role models.
Aurelia lived with her aunt and they were very close, like two peas in a pod. Flora seems to have been a mix of older sister and surrogate mother for Aurelia.

The nearly three years they had in Bucharest were the happiest of their adult lives for both of them. They had their important jobs, they shopped, went to the pictures, went back to the countryside from time to time, met visiting friends and relatives, relaxed in the central park of Bucharest, Cismigiu, with a book. When Constance came to Bucharest, she naturally stayed with them, so this would be all three of them opposite my old highschool. 
<figure>
<img src="./post4/cismigiu.png"
     style="display: block; margin: auto; width: 300px" />
     <figcaption style="text-align: center;" ><i>Flora and Aurelia in the park</i></figcaption>
</figure>
Anyway, the start of the second world war put an end to this. Flora also moved back to the countryside, in a different village, Bascov, in the same Arges county, and continued to work as a midwife. There was talk of a sweetheart in her youth in Bucharest but she never married or had children of her own.

Aurelia was pregnant in the second half of 1945 and it was Flora that was her midwife on that day in December. Winter was terrible that year according to a few sources I could find. It was also the end of the war, soviet troups everywhere. Was it Aurelia’s choice to give birth at home or there was just no other option for her care? Was it normal to give birth at home for all social classes? Was there an attempt to get Aurelia to the hospital? Were the roads unusable because of snow? Did Aurelia feel more comfortable with her aunt? Too traumatic, these details were never talked about.<br>
By then, Flora was a very experienced midwife but things went terribly wrong and Aurelia died. Flora was devastated, grieving and unconsolable. “The light went out of Flora then”, Constance later described the moment. Add supporting the devasted, blaming-herself sister to the list of Constance’s burdens. 
We know now that medical professionals should not treat, operate on family members. Was it the same then and there? Was there a choice? My mother says that this is the only time when a pregnant woman died in Flora’s care. Impossible to confirm but terrible either way.
Flora continued to work as a midwife after this. There was probably little choice with all the family wealth gone after the war.

My mother, Mariana, remembers Flora as a very old woman in mourning, looking older than Constance. Mariana describes a scene when Flora humbly gave her a wrinkled, bundled tablecloth as a gift. Little Mariana had no need of a tablecloth and later told Constance that it’s ugly and she doesn’t want it but Constance said this is a special one and she should take good care of it. Mariana did take care of it, then and for decades later. Poor Flora poured her soul into embroidering this table cloth. I hope she found some solace and felt close to her beloved Aurelia.
 
I didn’t like the tablecloth as a child, it was too elaborate, too old-fashioned, I wanted something bright and colourful or no tablecloth at all. For the last 18 months, the table cloth has been in our dining room, a clear plastic cover on top, embroidered angels and all. It is about the same age as our house. It is there for all our meals, a tangible link to these two remarkable women
<figure>
<img src="./post4/tablecloth.png"
     style="display: block; margin: auto; width: 300px" />
     <figcaption style="text-align: center;" ><i>The tablecloth</i></figcaption>
</figure>
I like to imagine there is a parallel universe somewhere where it’s forever 1930s in Bucharest and Aurelia and Flora are in the park laughing and reading books.
`
    content = postText
	}


const summary = Post4Summary(lang)
return {
	content,
	linkId: summary.linkId,
	title: summary.cardTitle
}
}

export default Post4;
