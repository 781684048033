const Post7Summary = (lang) => {
	const isRom = lang === 'rom'

	
	return {
		lang,
		cardTitle: isRom?  'Taica Popa, “Un om intre oameni”' :'Father',	
		cardText:  isRom ?  'Aceasta este povestea singurului preot din familia mea extinsă, a stra-stra-unchiului meu și a cadoului special pe care l-a făcut părinților mei după ce le-a săvârșit slujba de căsătorie.': 
		'This is the story of the only priest in my extended family, my great-great uncle and the special gift he gave to my parents after performing their marriage ceremony.', 
		photoPath: '/post7/collage7Long.png', 
		photoPathShort: '/post7/collage7s.png', 
		linkId: 'father'
	}

}

export default Post7Summary;
