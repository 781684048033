
import Post10Summary from "../summary/Post10Summary";

const Post10 = (lang) => {
	let content
	if (lang === 'rom') {
		const romText = `
# Vocatie

Mi-am pierdut tatăl mult prea devreme, la scurt timp după ce a împlinit 56 de ani.
Cu toate acestea, ceea ce scriu aici nu este din perspectiva unei fiice careia îi lipsește tatăl (deși sunt și asta, evident), 
ci din perspectiva unui profesionist, inginer de software senior și inventator cu experienta ( titlurile mele sunt relevante pentru ceea ce urmează de aceea le pomenesc aici).

Tatăl meu, Alexandru, a fost un profesor de matematică cu vocatie. A urcat profesional (în România, profesorii au grade didactice și el l-a obținut cel mai înalt) dar și ca responsabilitati 
(șef al catedrei de matematică, director adjunct și apoi director în ultimii, cred, șase ani din viață).
Întotdeauna am știut că a fost un profesor excelent, cu o vocatie adevarata. 
Nenumărați foști elevi au spus si scris asta și aș putea include aici multe dovezi. 
Aș putea să menționez si că mulți dintre colegii lui, profesori de matematică, împrumutau și copiau multe dintre 
materialele și planurile lui de lecție, deoarece erau model.
<figure>
<img src="./post10/dad.png"
	style="display: block; margin: auto; width: 300px" />
	<figcaption style="text-align: center;" ><i>Tata la începutul carierei sale într-o școală sătească</i></figcaption>
</figure>


Dar sunt câteva lucruri pe care le-am descoperit mai recent.

Am amintit in alte postari de minunatul obicei românesc de a face cadou cărți și de a scrie mesaje pe ele așa cum ai scrie pe felicitari, 
surprinzând astfel în carte acel moment de timp si acel sentiment.

Exista si un alt motiv pentru a semna cărțile, anume ca semn de proprietate. 
Cartile se împrumutau și, semnate, creștea șansa de a le recupera. 
Tata își semna și data cărțile de matematică, în principal culegeri de probleme. 
Avea și o colecție a revistei „Gazeta Matematica”, si ea semnată și datată. 
Chiar nu era nevoie să tot cumpere, să le studieze și sa se auto-perfectioneze în permanenta. 
Majoritatea profesorilor de matematică aveau doua culegeri de probleme pentru întreaga lor carieră 
si considerau ca dupa facultate nu mai trebuie sa inveti.  Dar tata nu era asa.

M-am uitat recent prin acele cărți ale lui. Sunt mult prea multe pentru ceea ce aș putea avea nevoie vreodata (sper să găsesc un loc unde să le pot darui). 
M-am hotărât, desi nu imi place sa stric cărți, să iau aceste primele pagini cu semnăturile și datele lui, ca niște autografe. 
Cu date din anii 70, 80 și 90, aceste pagini sunt dovada peste timp a vocatiei si efortului de auto-perfectionare permanenta.
<figure>
	<img src="./post10/signatures.png"
	style="display: block; margin: auto; width: 450px" />
	<figcaption style="text-align: center;" ><i>Primele pagini ale cărților de matematică ale tatălui meu cu semnăturile și datele lui</i></figcaption>
</figure>


Pentru a obține calificarea de profesor de grad 1,
 candidații trebuiau să facă, printre altele, si o cercetare originală. 
 Pe lucrarea tatălui meu scrie anul 1980, dar cea mai mare parte a muncii lui a fost făcută in anii 70.

Cunosc această lucrare de copil, dar, și mi-e rușine să spun asta, nu am trecut de aparențe: scrisa la mașină de scris de o secretară, simboluri matematice adăugate sau corectate de mână, diagrame desenate cu o riglă și stilou.

„Oh, ce ciudat și învechit” ma gândeam, mai ales de când folosisem un word processor la facultate. 
Nu am fost mai breaza decât copiii de azi spunând „Cum, nu aveai tabletă când erai mic? Săracul de tine!” în timp ce ei se joacă cu o aplicație, 
nu contează că unii dintre noi au făcut programare adevarata de zeci de ani și stim mai multe desre tehnologie decit au visat ei vreodata.

Când m-am uitat bine la această lucrare, mi s-a taiat respiratia. 
Deși nu a folosit niciodată un computer, tata a fost programator și viziunea lui era foarte avansata, mai mult decat, sunt sigura, și el și-a dat seama.
Titlul lucrării sale este „Algoritmi si scheme logice. Legătura cu unele probleme si procese de calcul din programa școlară”. 
Lucrarea are 135 de pagini tiparite pe o singură față a colii de hirtie. De ce a ales aceasta tema nu știu.
<figure>
<img src="./post10/grade1.png"
	style="display: block; margin: auto; width: 500px" />
	<figcaption style="text-align: center;" ><i>Lucrarea de Gradul I</i></figcaption>
</figure>

Primele capitole descriu istoria algoritmilor, calculul boolean, diferite moduri de reprezentare a programelor de calculator pe hârtie (în esență pseudo-cod și scheme logice și modul de a descrie pasii de execuție ale programelor).

Aproximativ la jumătatea lucrariii, tata include programele sale, de la cele simple 
(rezolvarea de ecuații liniare și pătratice, sisteme de ecuații 2 x 2, procesare de mulțimi și vectori, calcularea valorilor polinoamelor) până la metode numerice pentru multe probleme mai complexe 
(rezolvarea ecuațiilor polinomiale și a ecuațiilor transcendentale, calculul rădăcinilor pătrate, calculul coeficienților binomiali, calculul valorii numarului e, baza logaritmului natural cu un anumit grad de precizie, calculul integralelor complexe) 
si multe alte programe (schimbarea bazelor numerice pentru un număr, calculul determinantului unei matrice cu  metoda Gauss, descompunerea în factori primi, găsirea numerelor pitagoreene pentru un număr fix n, produs de matricii etc).
Pentru toate acestea, el a inclus pseudo-cod, schemă logică, matematica pe care se bazeaza algoritmii
si exemplele de execuție.
<figure>
<img src="./post10/programming.png"
	style="display: block; margin: auto; width: 500px" />
	<figcaption style="text-align: center;" ><i>Citiva algoritmi
	</i></figcaption>
</figure>

Când am început ca programator, colegii mai în vârstă spuneau „voi copii, sunteți atât de norocoși, scrieți programe și primiți feedback imediat daca merge sau nu.
 Noi o faceam cu cărtele perforate și trebuia să așteptăm mult să vedem dacă funcționează”. 
 Aș fi putut să spun „tatăl meu a făcut asta cu pix și hârtie și doar cu mintea lui pentru a verifica corectitudinea programului”

Iată și bibliografia lui: cursuri universitare familiare și profesori pe care i-am avut si eu mai târziu, inclusiv Biblia programării computerelor The art of Computer Programming, de Donald Knuth din 1969.
Tata nu știa engleza, îmi imaginez traducerea fotocopiată pe care trebuie să o fi consultat.
Am văzut astfel de copii si ca studenta. De asemenea, stiu ca aceste lucrări erau cursuri de nivel universitar, 
nu se preocupau de probleme de gimnaziu.
<figure>
<img src="./post10/conclusion.png"
	style="display: block; margin: auto; width: 500px" />
	<figcaption style="text-align: center;" ><i>Bibliografia si concluzia.
	</i></figcaption>
</figure>

Probabil cea mai mare surpriza pentru mine se află în secțiunea de Concluzii. 
Știam că  a condus cercul de matematică în școală si ca a renuntat la această responsabilitate in favoarea unei alte profesoare
 cînd eram eu la școală și mergeam la cerc. 
 Copiii care veneau la acest cerc erau mai înzestrați pentru matematica si rezolvau probleme dificile, pregătindu-se pentru competiții de olimpiade de matematică, importante în România, atunci și acum.

Dar nu știam că în timp ce conducea clubul le preda acelor elevi cum să programeze cu cretă pe tablă în anii ’70 într-o școală modesta de cartier din București!

Iata citeva pasaje din secțiunea finală a lucrării sale (editate pentru concizie):


_Informatica s-a dezvoltat spectaculos in ultimii ani, atit pe plan mondial cit si la noi, fapt care exercita o puternica influenta asupra tinerei generații. 
Initierea elevilor in tainele acesteri discipline inca de la aceasta virsta scolara medie, orientarea lor spre aceste domenii, credem ca este posibila si in acelasi timp necesara […]
Activitatile care se pot desfasura in cadrul cercului de matematica au o deosebita importantă pentru formarea priceperilor si deprinderilor de calcul, 
pentru dezvoltarea spiritului de inventivitate si a gindirii logice a copiilor._
_Faptul ca aplicarea algoritmilor la rezolvarea unor probleme duce in mod sigur si intotdeauna la rezultatul dorit, 
faptul ca se realizeaza o economie importanta de timp ofera multe satisfactii morale elevilor si profesorului. 
Universalitatea algoritmilor - faptul ca se pot aplica la clase intregi de probleme de acelasi tip constituie un argument in plus pentru abordarea cu curaj a rezolvarii unor probleme pe cale algoritmica._
_Din discutiile purtate deseori cu elevii in cadrul orelor de matematica, 
orelor educative sau activitatilor cercului de matematica am deprins ideea ca tehnica moderna de calcul exercita pentru multi elevi o puternica atractie.
Deci, puterm afirma ca prin aceste activitati ne aducem o contributie si la rezolvarea acestei probleme importante a scolii care este orientarea scolara si profesionala.
Desigur ca nu putem afirma ca se pot forma viitori specialisti informaticieni prin activitati de cerc, dar atragerea copiilor catre acest domeniu, initierea lor in secretele acestei stiinte se pare realiza acum […]
Ne revine noua, slujitorilor scolii, sarcina de mare raspundere de a ajuta elevii sa-si insuseasca cele mai inalte cunostinte, de a sadi in constiinta lor dragostea de munca, de patrie, de a forma si educa tineretul scolar pentru munca si viata._

Si-a dat seama si de importanta infrastructurii.

_Cercetarea stiintifica va trebui să găsească soluții pentru construirea unui sistem informatic național funcțional,
inclusiv o reţea de calculatoare şi sisteme de transmisie a datelor care vor avea o profundă
influența asupra dezvoltării economice și sociale_ 
<figure>
<img src="./post10/dad2.png"
	style="display: block; margin: auto; width: 400px" />
	<figcaption style="text-align: center;" ><i> La scoala
	</i></figcaption>
</figure>

Pot pune asta într-un context international.  Ce se întâmpla la acea vreme cu educația informatică din gimnaziu in alte parti ale lumii?

Iată câteva fapte de la https://en.wikipedia.org/wiki/Computing_education

_Cercetarea în educația informatică a apărut ca domeniu de studiu în anii 1970, 
când cercetătorii au început să exploreze eficiența diferitelor abordări ale predării programării computerelor.
Educația în domeniul informaticii a fost predată în principal doar la nivel universitar până de curând, 
cu unele excepții notabile în Israel, Polonia și Regatul Unit._

Sa ne uitam la una dintre aceste țări avansate, [Marea Britanie](https://en.wikipedia.org/wiki/Computer_science_education_in_the_United_Kingdom).

Vorbesc aici de Computing si nu IT (adică programare si nu utilizarea computerelor care a fost introdusa mai devreme). 
Computing a devenit o materie de studiu optionala pentru vârsta școlară despre care vorbim, adică copiii de 11-14 ani abia in 2014, 
deși elemente de logica si hardware-ul au fost predate si în anii 1980, dupa 1990 nu s-a facut nici o programare pina la 16 ani.

Tata era foarte modest, dar, cu siguranță, trebuie să fi știut cât de grozav era ca profesor de matematică. 
Mă doare să stiu, caci sunt convinsa de asta, că nu și-a dat seama niciodata cât de bun si vizionar a fost ca informatician. 
Da, chiar cred că a fost asta (titlurile alea ale mele pe care le-am menționat la inceput? Cu aceasta baza afirm asta).

Stiu ca e neproductiv sa te gindesti la “dar, daca?”. Dacă si cu parcă. 
Dar nu mă pot abține, uneori, să mă gandesc ce-ar fi fost dacă tata ar fi avut putere de decizie si cu intelegerea lui a ce va urma, 
ar fi introdus niste cursuri optionale de programare in gimnaziu incepind din anii 1980 și cu specialiștii educați la acea vreme, unde ar fi fost Romania acum?

Uneori, oameni mari sunt chiar lângă noi, dar ne trebuiesc ani, cunostințe, experiență și perspectivă pentru a-i înțelege și a-i aprecia la adevarata valoare.

`
		content = romText
	} else { 
const postText = `
# Pursuit of excellence

I have lost my dad way too early, soon after he turned 56. 
However, what I am writing here is not from the perspective of a daddy’s girl hero-worshipping and missing her dad all those years later (though I am that too, obviously) but from the perspective of a profesional, a senior software engineer (this is relevant for what follows).

My dad, Alexandru, was a dedicated math teacher. He climbed that career path, both professionally (in Romania, the teachers have professional levels and he achieved the highest) and as positions: head of math, deputy headteacher and then headteacher for the last, I think, six years of his life.
We always knew that he was an excellent and dedicated teacher with countless pupils and former pupils saying this and I could include here tons of those records. 
I could also mention how many of his fellow math teachers, some friends that were visiting us when I was a child, would borrow and copy many of his materials and lesson plans as they were excellent.
<figure>
<img src="./post10/dad.png"
	style="display: block; margin: auto; width: 300px" />
	<figcaption style="text-align: center;" ><i>Dad at the beginning at his career in a village school</i></figcaption>
</figure>


But there are a few more surprising things that I discovered more recently.

I mentioned previously the lovely Romanian custom of gifting books and writing messages on them as you would write on the cards, thus capturing in the book that moment in time. 
There was another reason to sign your books as a sign of ownership when you would lend them and then increase the chance of getting them back. 
Dad would sign and date his math books, 
mainly collections of problems. Most math teachers had a couple of books of problems for their whole careers.

I recently looked through those books. Way too many for what I could possibly need or want (I hope to find some place to gift them) I decided for all my unease of physically taking anything out of books to take these first pages with his signatures and dates, like some autographs. 
With dates from 70s, 80s and 90s, these pages tell the story of his pursuit of excellence and the growth mindset before this was a thing. 
<figure>
	<img src="./post10/signatures.png"
	style="display: block; margin: auto; width: 450px" />
	<figcaption style="text-align: center;" ><i>The first pages of some of my dad's math books with his signatures and dates</i></figcaption>
</figure>

To get that “grade 1” teacher qualification, among other things, the candidates had to do a piece of original research. Dad’s one has the year 1980 written on it, but most of his work was done in the middle to late 70s.
I have known of this work forever, but, and I am so ashamed to say this, I didn’t look past the appearances: typed on a typewriter by a secretary he employed, math symbols added or corrected by hand, diagrams drawn with a ruler and pen. 
“Oh, how quaint and antiquated!” I thought, especially when I had wordprocessor and editors. 
I wasn’t much better than today’s kids saying “what, you didn’t have a tablet when you grew up? Poor you!” while they play with some app, never mind that some of us were hacking for decades and can 
out-program them out of the park.

When I properly looked at this work, I got goosebumps. 
Though he never really used a computer, dad was a programmer and his vision was ridiculously ahead of his time, 
much more that I am sure, even he realised.
The title of his work is “Algorithms and Logical Schemes. Links with computational problems from the school curriculum“. 
There are 135, single sided pages. Why he would pick this topic in the 70s I don’t know.
<figure>
<img src="./post10/grade1.png"
	style="display: block; margin: auto; width: 500px" />
	<figcaption style="text-align: center;" ><i>Dad's work</i></figcaption>
</figure>
The first chapters describe the history of algorithms, boolean calculus, different ways of representing computer programs on paper (
essentially pseudo code and logical schemes and tracing execution). 
About half way through, he shows a few of his programs, from simple ones (linear and quadratic equations, 2 x 2 systems of equations, 
sets and arrays processing, computing polynomial values) to numerical methods for many problems (solving polynomial equations and 
transcendental equations, computing square roots, computing binomial coefficients, computing the Euler number to certain degree of precision, 
computing complex integrals) to a variety of other programs (converting a number to different bases, computing 
the determinant of a matrix with the Gauss methods, decomposing a numver in prime factors, finding pitagorean numbers for a fixed number n, matrix product etc).
For all of these, he showed pseudocode, logical scheme, the math behind it and execution stacks.
<figure>
<img src="./post10/programming.png"
	style="display: block; margin: auto; width: 500px" />
	<figcaption style="text-align: center;" ><i>A few of the algorthms
	</i></figcaption>
</figure>
When I started as a programmer, older colleagues would say “you kids, you are so lucky, you type your program in and immediately see if it works. 
We had to do it with punch cards and wait and see if it works”. I wish I would have known to tell them 
“my dad did this with pen and paper and only with his mind to verify correctness.” 

Here is also his bibliography: familiar university courses and professors that I would later study, including the Bible of computer programming "The art of Computer Programming", by Donald Knuth from 1969. 
Dad didn’t know English, I can imagine the photocopied translation he must have consulted.
I have seen such copies as a student. I am also familiar with many of these works. 
They were university level courses, they would not concern themselves with middle school problems.
<figure>
<img src="./post10/conclusion.png"
	style="display: block; margin: auto; width: 500px" />
	<figcaption style="text-align: center;" ><i>The bibliography and the conclusion.
	</i></figcaption>
</figure>
Probably the most astonishing  discovery for me is in his Conclusions section. I knew he lead a math club in the school though he passed this responsibility to a different teacher while I attended the school and club. 
Kids that were more gifted for the subject would solve difficult problems, preparing for math olympiads competitions that were a very big deal in Romania, then and now. 
But I didn’t know that while he lead the club he taught those pupils programming with chalk on the blackboard in the ‘70s in a school in a working class neighbourhood in Bucharest.

He says in the final section of his work (edited for brevity):

_Computer science has made big advances in the last few years, both internationally and in Romania. The discipline has a strong influence on the aspirations of the younger generations. Starting the pupils on this subject from the middle school is not only possible but necessary to develop inventive and logical thinking …  It is possible to start in the math club first and experiment how pupils respond to it.
The speed of the algorithms achieving the desired results when compared to computing by hand gives a big satisfaction to both teachers and pupils. The universality of the algorithms, the fact that they can be applied to a class of problems is an extra argument to bravely attempt algorithmic solutions for many problems.
Discussing with pupils during math classes and clubs I can see how fascinated they are for this area where their future may lay and how the school must adapt to support them. Obviously they will need to continue these studies in high school and at universities, but we can make a start in middle school. 
It falls on us, the education’ first line of workers to achieve the big responsibility of educating pupils for tomorrow’s world to give them the latest knowledge to seed in their consciousness the passion for work and country, to form and educate the pupils for their careers and lives._

He also recognised the importance of the computer infrastructure:

_Research will have to find solutions for building a working national informatics system, 
including a network of computers and data transmission systems which will have a profound 
influence in economic and social development_ 
<figure>
<img src="./post10/dad2.png"
	style="display: block; margin: auto; width: 400px" />
	<figcaption style="text-align: center;" ><i>Practicing what he preached
	</i></figcaption>
</figure>

I can put this in an international context. 
What happened with computing education in middle school at the time?
Here are a few facts from https://en.wikipedia.org/wiki/Computing_education :

_Computer science education research emerged as a field of study in the 1970s, when researchers began to explore the effectiveness of different approaches to teaching computer programming. 
Computer science education has primarily only been taught at university level until recently, with some notable exceptions in Israel, Poland and the United Kingdom with the BBC Micro in the 1980s as part of Computer science education in the United Kingdom._

Looking at  one of these advanced countries, [UK](https://en.wikipedia.org/wiki/Computer_science_education_in_the_United_Kingdom), only in 2014 did Computing
 (i.e. programming as opposed to the use of computers IT, Word, Power Point etc) became a subject for 11-14 year olds though logic, hardware and binary were taught in the 1980s.

Dad was very modest but, surely, he must have known how great he was as a math teacher, 
but it pains me to think he did not realise how good and ahead of his time he was as a theoretical computer scientist. 
Yes, I really think he was (remember those titles of mine I mentioned? That’s where they come in).

I cannot help sometimes indulging in a little what-ifs? 
What if dad was in charge of curriculum and with his vision we had programming classes from the 1980s and the specialists educated at that time. Where would Romania be now?

Sometimes, the greatest people are right next to us but it might take years, experience and perspective to understand and appreciate them. 
`
    content = postText
	}


const summary = Post10Summary(lang)
return {
	content,
	linkId: summary.linkId,
	title: summary.cardTitle
}
}

export default Post10;
