
import React from "react";
import { Link, Stack } from '@mui/material';
import Markdown from '../Markdown';
import PostCommentsComponent from "../PostCommentsComponent";
const PostComponent = ({ lang, linkId, text, leftLinkId, leftLinkText, rightLinkId, rightLinkText, noMainLink, likes, comments, onLikesUpdated, onCommentsUpdated }) => {
    const prev = lang === 'rom' ? 'Inapoi: ' : 'Previous: '
    const next = lang === 'rom' ? 'Inainte: ' : 'Next: '
    const main = lang === 'rom' ? 'Prima pagina' : 'Main page'
    const i = leftLinkId?.indexOf('?lang=')
    if (i > 0) {
        leftLinkId = leftLinkId.substring(0, i)
    }
    const j = rightLinkId?.indexOf('?lang=')
    if (j > 0) {
        rightLinkId = rightLinkId.substring(0, i)
    }
    return (
        <>
            <a id={linkId} sx={{ paddingTop: '20px' }}> </a>
            <Markdown className="markdown" sx={{ display: 'relative' }} key={linkId} >
                {text}
            </Markdown>
            <Stack
                direction={{ xs: 'column', sm: 'column' }}
                spacing={{ xs: 1, sm: 1, md: 1 }}
                justifyContent="space-around"
                alignItems="center"
            >
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 5, md: 8 }}
                    justifyContent="space-around"
                    alignItems="center"
                >
                    
                    {leftLinkId && <Link href={`${leftLinkId}?lang=${lang}`}>{prev} {leftLinkText}</Link>}
                    {!noMainLink && <Link href={`/?lang=${lang}`}>{main}</Link>}

                    {linkId !== '#intro' && rightLinkId && <Link href={`${rightLinkId}?lang=${lang}`}>{next} {rightLinkText} </Link>}


                </Stack>
                {/* <>
                Any comments or feedback? - Head to Main Page or Instagram
                    <Link href="https://www.instagram.com/mytreasurebox_rom/" target='_blank' style={{ color: "black" }}>
                    <InstagramIcon />
                    </Link>
                </> */}
            <PostCommentsComponent 
                lang={lang} 
                postId={linkId}
                likes={likes}
                comments={comments}
                onLikesUpdated={onLikesUpdated}
                onCommentsUpdated={onCommentsUpdated}
                />
            </Stack>
            {/* <Link></Link> */}
            <hr />
            <br />
        </>
    )
}

export default PostComponent;
