
import Post9Summary from "../summary/Post9Summary";

const Post9 = (lang) => {
	let content
	if (lang === 'rom') {
		const romText = `
# Pe marginile istoriei
Nu-mi plăcea în mod deosebit bunicul meu matern: era rece, pretențios și avea niște idei ciudate (cum ar fi să săruți copiii doar în timp ce dorm, altfel copiii vor fi răsfățați,pe bune, bunicule?). S-ar putea să fi moștenit câteva lucruri neobișnuite de la el dacă e o baza genetica pentru asa ceva(preferințe pentru semințele încolțite, culesul si consumatul plantelor sălbatice și plimbarilor pe câmp).
I-am respectat realizările profesionale uimitoare ca inginer agronom (o să scriu despre asta altă dată) și am apreciat că banca Bunicul a fost de ajutor financiar părinților mei în mai multe ocazii.

Aici voi descrie modul în care un combinatie de noroc, oameni generosi si întîmplare au facut ca in ciuda începutului său extrem de umil să cunoască și să primească ajutor de la o familie importantă din punct de vedere politic și cultural in istoria României.

Bunicul meu, pe nume Constantin și cunoscut la acea vreme sub numele de Costică, s-a născut intr-un un mic sat (populația actuală 175) din județul Bacău, estul României.
Când aveam vreo 8 ani, am mers în acest sat din Dacia modestă, de epoca comunistă, a tatălui meu. Sosirea mașinii noastre a făcut furori în sat, ceea ce ar fi putut fi tot satul venind să-l vadă.
<figure>
<img src="./post9/dacia.png"
	style="display: block; margin: auto; width: 200px" />
	<figcaption style="text-align: center;" ><i>O masina ca a tatalui meu</i></figcaption>
</figure>
Se spunea in glumă din acea epocă: sunt locuri în România care au gară, locuri cu care au haltă, locuri prin care trece trenul și locuri în care oamenii au auzit de tren. Acesta a fost cu siguranță un loc în care poate oamenii ar fi auzit despre tren.

Cu aproximativ 75 de ani înainte de vizita noastră, Costică s-a născut acolo, cumva fără tată. Nu știu care a fost povestea acolo, dar aceasta nu a fost o epocă sau un loc foarte tolerant cu mamele singure, așa că Costică a început cu adevărat de jos. Evident, nu există fotografii cu el în copilărie sau adolescent.
<figure>
	<img src="./post9/costica.png"
	style="display: block; margin: auto; width: 250px" />
	<figcaption style="text-align: center;" ><i>Costica la 37 ani</i></figcaption>
</figure>
Costică era foarte inteligent. A început școala primară într-un sat mai mare, la 40 de minute de mers pe jos de a lui. Invatatorul din școala primară i-a observat potențialul și l-a susținut calea către o viață mai bună prin școala. De obicei, învățătorul îi convingea pe părinți, adică pe tatăl să accepte doua miine de lucru al cimpului mai puțin și să investească în schimb în școlarizarea copilului.

Având în vedere statutul social al mamei, probabil că aici a fost diferit, dar cumva, și asta a fost o mare realizare, Costică a început scoale secundara în cel mai apropiat oraș, Bacău. (o călătorie de 6 ore pe jos din satul său conform Google Maps). Costică a fost cunoscut mai târziu pentru că a mers pe jos pe distanțe foarte mari prin câmpuri, dar chiar și pentru el ar fi prea mult pentru o navetă zilnică, așa că el (sau, mai probabil, profesorul) trebuie să fi găsit, un loc de cazare, fără îndoială, extrem de simplu. Costică s-a născut în 1907, așa că trebuie să fi urmat această școală din aproximativ 1918 până în 1925.

La școală, Costică s-a împrietenit cu un băiat pe nume Pătru Pătrășcanu. Atât tatăl, cât și fratele acestui băiat sunt semnificativi din punct de vedere istoric.
Tatăl lui Pătru a fost Dimitrie D. (DD) Pătrășcanu, scriitor, jurnalist, om politic, deputat, dramaturg și profesor.
<figure>
<img src="./post9/dd.png"
	style="display: block; margin: auto; width: 200px" />
	<figcaption style="text-align: center;" ><i>Dimitrie D. (DD) Pătrășcanu (credit wikipedia)</i></figcaption>
</figure>
DD Pătrășcanu, cunostea multe personalitati importante din cultura română la acea vreme ca în această fotografie cu el cu doi (dacă nu trei, depinde de programa) scriitori români pe care îi studiem la școală.
<figure>
<img src="./post9/writers.png"
	style="display: block; margin: auto; width: 300px" />
	<figcaption style="text-align: center;" ><i>DD Patrascanu la mijloc, cu scriitorii Gala Galaction, Mihai Sadoveanu and Panait Istrate (credit wikipedia)
	</i></figcaption>
</figure>
DD Pătrășcanu a avut doi fii, cel mai mare, Lucrețiu născut în 1900 și Pătru (nici o pagină de wikipedie convenabilă pentru el) care trebuie să fi fost de aceeasi virsta cu Costică.

Faptul că doi băieți de statut social atât de diferit, Costică și Pătru au studiat în aceeași școală și s-au împrietenit este cu adevărat remarcabil. 
Cât de diferită ar fi fost viața lui Costică fără această prietenie de școală.

Pătru l-a invitat pe Costică acasă unde l-a întâlnit pe tată. Pătrășcanu Sr. l-a plăcut pe Costică și a fost, ceea ce am spune astăzi, mentor, model și consilier de carieră.

Și mai surprinzător și incredibil de generos din partea lui, Pătrășcanu Sr l-a ajutat financiar pe Costică. 
Exact cu cât nu știm, nu a fost ca o bursă oficială, dar a fost ceva în timpul școlii și, în special, a ajutat la mutarea lui Costică la București pentru a studia la Institutul de Agronomie 
odată ce a terminat școala.

Odata ce a ajuns student la București, Costică s-a întreținut singur cu diverse slujbe și a fost de o economie/zgircenie care nu l-a părăsit niciodată, nici când făcea bani frumoși. 
Dar a avut acest ajutor crucial în momentul cheie din viața lui de la DD Patrascanu.
<figure>
<img src="./post9/uni.png"
	style="display: block; margin: auto; width: 300px" />
	<figcaption style="text-align: center;" ><i>Institutul Agrnonomic pe care l-a urmat Costica.(credit website-ul unversitatii)
	</i></figcaption>
</figure>
Pătrășcanu Sr a murit în 1937, așa că cel puțin a fost ferit de unele dintre nenorocirile care vor veni.

Celălalt frate a fost [Lucrețiu Pătrășcanu](https://ro.wikipedia.org/wiki/Lucre%C8%9Biu_P%C4%83tr%C4%83%C8%99canu) (pagina lui este sursa majorității rezumatul meu de mai jos),
Probabil că Lucretiu s-a suprapus in timp vreun an cu timpul petrecut în Bacău pe Costică și probabil că nu a acordat nici o atenție prietenului sarac al fratelui său mai mic. 
Invers,  Costică il cunoștea bine și auzea frecvent vești despre Lucrețiu, chiar și după ce a plecat de la Bacău.
<figure>
<img src="./post9/lucretiu.png"
	style="display: block; margin: auto; width: 200px" />
	<figcaption style="text-align: center;" ><i>Lucrețiu  Pătrășcanu (credit wikipedia)
	</i></figcaption>
</figure>
Lucrețiu a intrat în politică ca student, facind o politica din ce în ce mai mult de stânga, în timp ce a urmat facultatea de drept la București și, mai târziu, a obtinut un doctorat la Universitatea din Leipzig. 
A fost foarte activ ca jurnalist și s-a radicalizat mai mult după revoluția bolșevică din Rusia. 
A fost unul dintre membrii fondatori ai Partidului Comunist din România în 1921, a călătorit frecvent la Moscova, a fost arestat în repetate rânduri în perioada interbelică și a intrat în greva foamei în timpul unei arestări.
<figure>
<img src="./post9/hammer.png"
	style="display: block; margin: auto; width: 100px" />
	<figcaption style="text-align: center;" ><i>Simbolul communist reprezentind solidaritea dintre tarani si muncitori
	</i></figcaption>
</figure>
Unii istorici spun că la sfârșitul anilor 30 (epurările staliniste ar putea avea ceva de-a face cu asta) 
Patrscanu a inceput sa aiba îndoieli cu privire la stalinism, dar a ales să acorde prioritate opoziției față de fascism și a rămas activ în PCR.

A fost în închisoare o buna parte din timpul celui de-al Doilea Război Mondial, surprinzator eliberat de guvernul de extremă dreaptă și apoi arestat din nou.

Pătrășcanu s-a întâlnit cu reprezentanți ai tânărului rege român Mihai I în aprilie 1944 pentru a media un acord între monarh și comuniști privind o mișcare pro-aliată de a răsturna șeful statului, Antonescu, și de a retrage România din Axa. La acea vreme,  România lupta cu sovietici pe Frontul de Est. 
Cumva, datorită trecutului și educației sale, Pătrășcanu a fost chipul acceptabil al comunismului pentru celelalte partide și pentru Rege, în timp ce angajamentul său evident față de cauză l-a făcut un comunist adevărat, sau așa părea.

Lovitura de stat planificată a avut loc la 23 august 1944, iar Pătrășcanu a fost autorul proclamației către țară pe care regele a citit-o la radio imediat după lovitură. 
Pătrășcanu a semnat și armistițiul României cu sovieticii, la 12 septembrie 1944. Pătrășcanu a fost membru al delegației României la Conferința de Pace de la Paris, și unul dintre semnatarii Tratatului de Pace pentru România.

El a fost în mare parte responsabil pentru succesul pe care partidul comunist l-a avut în controlul cadrului legal al României în următorii ani. 
Este greu de rezumat toate detaliile din acea perioada, dar cred că este corect să spun că, în timp ce el a încercat să stopeze unele dintre excesele sovietice și să protejeze interesele naționale, 
în același timp, a contribuit sau chiar conceput la unele dintre cele mai oribile politici ale acelei epoci.

Pătrășcanu a fost progresiv marginalizat în interiorul Partidului. Presa comunistă a încetat să se mai refere la Pătrășcanu drept „tovarăș”, și a folosit în schimb formula mai îndepărtată „Profesorul Pătrășcanu”. 
Acest lucru sună ridicol, dar a fost un semn.

Pe 28 aprilie 1948, Pătrășcanu a fost arestat pentru prima dată de iubiții săi comuniști. 
În închisoare, a încercat să se sinucidă de două ori. El a fost acuzat că a fost finanțat de personalități „burgheze” în timpul campaniei electorale și că ar fi fost agent dublu.
Pătrășcanu a fost ținut în detenție până în 1954, se presupune că a fost și torturat și apoi a fost executat la 17 aprilie în urma unui proces inscenat. 
Pătrășcanu a refuzat să fie reprezentat de un avocat, ba chiar să-și organizeze propria apărare și a declarat:

<p style="text-align: center;">
_Nu am nimic de spus, cu excepția faptului că scuip pe acuzațiile aduse împotriva mea._
</p>
Ultima întorsătură din acesta poveste este că ultimul lider comunist român, Nicolae Ceaușescu, l-a reabilitat postum pe Pătrășcanu în aprilie 1968, în încercarea de a-și discredita predecesorii și de a-și stabili propria legitimitate confirmând că toate acuzațiile au fost inventate.

Evident, nu sunt specialista, dar cred că orice simplă caracterizare a lui Pătrășcanu fie ca erou, fie ca ticălos, ca victimă sau infractor nu acoperă adevărul complex. 
Cum ar putea un om evident, foarte inteligent, educat, carismatic, curajos, cu siguranță bine intenționat, 
militant de partea numerosilor români care traiau in săracie crunta să ajungă să susțină un sistem criminal, amestecându-se cu multe figuri sinistre și în cele din urmă să fie ucis de ei? 
A fost dorinta de putere? O anumită arogantă că eliminarea controalelor și echilibrului puterilor este bine atunci când se aplică altora? 
Cred că povestea lui e tragică care ar trebui să fie o lecție dacă am putea învăța din ea.

Ce legătură are asta cu bunicul meu? Nu foarte mare. El a fost la marginea acestei familii influente și norocos că i-a cunoscut. 
Bunicul nu are prea multe de-a face cu povestea ulterioară a lui Lucrețiu, dar este o poveste prea interesantă și unică să nu mai vorbim aici.

Opiniile ulterioare ale lui Costică au arătat puțină simpatie pentru Lucrețiu „cum a putut face asta?” și mult mai multa pentru restul familiei Pătrășcanu, care, potrivit lui, a trecut prin toată gama de emoții de la mândria pentru realizările academice ale lui Lucrețiu, jena pentru opiniile sale politice, sentimentele de neînțelegere și trădare, 
îngrijorarea pentru soarta lui și apoi pentru a lor, întrucât păcatele unui membru al familiei ii afecta pe toți in acele timpuri.
Există o poveste de familie care sper să fie adevărată: mai târziu în viață bunicul meu, cind acesta era bine stabilit profesional, a putut să-și ajute vechiul prieten de școală, Patru, cu ceva important, deși nu am detalii. Sper ca a putut sa o faca si ca a facut-o.

Pentru familia mea, Pătrășcanu Sr a fost eroul care a ajutat cu generozitate un băiat sărac atunci când avea cea mai mare nevoie.
`
		content = romText
	} else { 
const postText = `
# On the fringes

I wasn’t particularly fond of my maternal grandfather: he was cold, demanding and had some strange ideas 
(like _one should only kiss kids while they are sleeping, otherwise the kids will be spoiled_, seriously, grandad?). I might have inherited a few unusual things from him if it was nature vs nurture that had anything to do with it (a liking for sprouted seeds, foraging and of walking in the fields). 
I respected his amazing professional achievements as agricultural engineer (I might write about this some other time) and I appreciated that bank of grandad has been helpful to my parents on a number of occasions. 

Here I will describe how his extremely humble beginnings resulted in him crossing paths and receiving help from a politically and culturally significant family whose son was a leading figure at the start of the communist system in Romania though he had a sorcerer’s apprentice sort of ending.

My grandad, named Constantin and known as Costică at the time was born at the bottom of the pile in the back of beyond, namely a tiny village (current population 175) in the county of Bacău, east Romania. 
When I was about 8 we went to this village in my dad’s modest Dacia. This was not the perfectly fine car that Dacia is today but a communist era one. 
The arrival of our car created a stir in the village with what might have been the whole village coming to see it. 
<figure>
<img src="./post9/dacia.png"
	style="display: block; margin: auto; width: 200px" />
	<figcaption style="text-align: center;" ><i>A car like my dad's</i></figcaption>
</figure>


A joke of that era went: there are places in Romania that have a train station, places with a train stop, places where the train passes through and places where people have heard about the train. This was definitely one place where people maybe might have heard about the train.

Some 75 years before our visit, Costică was born there to a single mum. I don’t know what was the story there, but this was not an an era or a place that was highly tolerant of single mums, so Costică really started at the bottom. Obviously there are no photos of him as a child or teenager. 

<figure>
	<img src="./post9/costica.png"
	style="display: block; margin: auto; width: 250px" />
	<figcaption style="text-align: center;" ><i>Costica in his late 30s</i></figcaption>
</figure>

Costică was highly intelligent. He started primary school in a bigger village, a 40 minutes walk from his own. 
The often unsung hero of the Romanian villages, the primary school teacher spotted his potential and supported him to a better life through education. 
I know of many examples, both lived and from various novels, of primary school teachers doing that. Usually, the teacher convinced the parents, i.e. the dad to take the hit of one less labourer and to invest instead in the schooling of the child. 

Given the mother’s social status, it probably was different here, but somehow, and this was quite a feat, Costică started secondary school in the closest town, Bacău. (a 6h trek on foot from his village according to Google Maps). Costică was later known for walking very long distances on foot across fields but even for him this would be too much for a daily commute so he (or more likely the teacher) must have found a, no doubt, extremely basic, place to stay. Costică was born in 1907 so he must have attended this school from about 1918 to 1925.

In school, Costică befriended a boy called Pătru Pătrășcanu. Both the father and brother of this boy are historically significant. 
Pătru’s father was [Dimitrie D. (DD) Pătrășcanu](https://en.wikipedia.org/wiki/Dimitrie_D._P%C4%83tr%C4%83%C8%99canu), a writer, journalist, politician, deputy,  dramatist and professor.
<figure>
<img src="./post9/dd.png"
	style="display: block; margin: auto; width: 200px" />
	<figcaption style="text-align: center;" ><i>Dimitrie D. (DD) Pătrășcanu (credit wikipedia)</i></figcaption>
</figure>

He mixed with who’s who of the Romanian culture at the time like in this photo of him with two (if not three, depends on the curriculum) Romanian writers that we study in school.
<figure>
<img src="./post9/writers.png"
	style="display: block; margin: auto; width: 300px" />
	<figcaption style="text-align: center;" ><i>DD Patrascanu in the middle, with writers Gala Galaction, Mihai Sadoveanu and Panait Istrate (credit wikipedia)
	</i></figcaption>
</figure>
DD Pătrășcanu had two sons, the older one, Lucretiu born in 1900 and Pătru (no convenient wikipedia page for him) who must have been born around the same time as Costică. 

The fact that two boys of such different social status, Costică and Pătru studied in the same school and became friends is really quite remarkable. How different Costică’s life would have turned out to be without this school friendship.

Pătru invited Costică to his home where he met the father (it’s probably safe to assume that there were no return visits). Pătrășcanu Sr took a liking on Costică and was, what we would call today, a mentor, role model and career advisor. 

Even more surprising and incredibly generous of him, Pătrășcanu Sr helped Costică financially. Exactly how much we don’t know, it wasn’t like an official scholarship, but it was something during the school and, in particular help with Costică moving to Bucharest to study at the Institute of Agricultural Studies once he finished the school. 

Once a student in Bucharest,  Costică supported himself with various jobs and was of a legendary tightness that never left him his whole life even when he was making good money. But there was this crucial help at the key moment in his life.
<figure>
<img src="./post9/uni.png"
	style="display: block; margin: auto; width: 500px" />
	<figcaption style="text-align: center;" ><i>The university that Costică attended, a very, very long way away from his humble beginnings.(credit the university's website)
	</i></figcaption>
</figure>

Pătrășcanu Sr died in 1937 so he was at least spared some of the horrors to come.

Now for the other brother, [Lucrețiu Pătrășcanu](https://ro.wikipedia.org/wiki/Lucre%C8%9Biu_P%C4%83tr%C4%83%C8%99canu) (his page is the source of most of my summary below),
He must have overlapped for a year with Costică’s time in Bacău and likely paid no attention to his younger brother's poor friend. On the other hand, Lucretiu was quite the star from early on, so Costică was well aware and frequently heard news about  Lucrețiu, even after he left  Bacău.
<figure>
<img src="./post9/lucretiu.png"
	style="display: block; margin: auto; width: 200px" />
	<figcaption style="text-align: center;" ><i>Lucrețiu  Pătrășcanu (credit wikipedia)
	</i></figcaption>
</figure>

Lucrețiu got into politics as a student, moving further and further left politically, while getting a law degree in Bucharest and later on, a PhD from University of Leipzig. He was very active as a journalist and become more radicalised after the bolshevic revolution in Russia. 
He was one of the founding members of the Communist party in Romania in 1921 (known as PCR), frequently travelled to Moscow, was arrested repeatedly during the inter war periods and went on hunger strike during one arrest. 
<figure>
<img src="./post9/hammer.png"
	style="display: block; margin: auto; width: 100px" />
	<figcaption style="text-align: center;" ><i>Communist symbol representing proletarian solidarity between agricultural and industrial workers
	</i></figcaption>
</figure>
Some historians say that during the late 30s (Stalinist purges might have something to do with it) he developed doubts about Stalinism itself but chose to prioritize opposition to fascism, and remained active in the PCR.
He was in prison during a good part of WWII though, bizarrely freed by the extremely right wing government and then rearrested. 

Pătrășcanu met representatives of the young Romanian King, Michael, in April 1944 in order to mediate an agreement between the monarch and the Communists regarding a pro-Allied move to overthrow
 the head of the state, Antonescu and withdraw Romania, which was fighting the Soviets on the Eastern Front, from the German-led Axis.  
 Somehow, because of his background and education, Pătrășcanu was the acceptable face of communism to the other parties and the King, while his obvious commitment to the cause made him a true communist, or so it seemed.

The planned coup took place on August 23rd 1944 and Pătrășcanu authored the proclamation to the country which the king read on National Radio immediately after the coup. 
He also signed Romania's armistice with the Soviets, on September 12, 1944.  
Pătrășcanu was a member of the Romanian delegation to the Paris Peace Conference after the war, and one of the signatories of the Peace Treaty for Romania.

He was largely responsible for the success of the communist party had in controlling Romania's legal framework for the following years. 
It’s difficult to summarize all of this, but I think it’s fair to say that he tried to curb some of the Soviet excesses and protect national interests 
but he also was responsible for some of the most horrible policies of that era.

Pătrășcanu was progressively marginalized inside the Communist party. 
The Communist newspapers ceased referring to Pătrășcanu as "comrade", and used instead the more distant formula "Professor Pătrășcanu". 
This sounds ridiculous as a slight, but it was a sign. 

On April 28, 1948, Pătrășcanu was first arrested by his beloved communists. 
In prison, he attempted suicide twice. He was accused of having been financed by "bourgeois" figures during the electoral campaign and being a double agent.
Pătrășcanu was kept in detention until 1954, allegedly he was also tortured and then he was executed on 17 April after a show trial. 
Pătrășcanu refused to be represented by a lawyer, and even to organize his own defense and stated: 

<p style="text-align: center;">
_I have nothing to say, except that I spit on the charges brought against me._
</p>
The last twist in his saga came in in April 1968: the last Romanian communist leader, Nicolae Ceaușescu, posthumously cleared Pătrășcanu's name of these accusations confirming that all charges were made up.

Obviously, I am hardly a specialist, but I think any simple characterisation of Pătrășcanu as either hero or villain, as victim or criminal does not cover the complex truth. How could an obviously very clever, educated, charismatic, brave, surely well intentioned man, with a valid grievance on behalf of many poor Romanians left behind by the system, end up supporting a criminal system, mixing with many sinister figures and ultimately getting killed by them? Was it the desire for power? Some arrogant belief that cutting the corners and removing checks and balances is fine when it applies to others? 
I think his is a tragic story that should be a lesson if only we could learn from it.

What does this have to do with my grandfather? He was on the fringes of this influential family and was lucky enough to have met them. 
Otherwise, he had nothing  much to do with Lucretiu’s later story, though it’s too interesting and unique not to mention it here. 

Costică's later views showed little sympathy for Lucretiu “what was he thinking?” and a whole lot more sympathy for the Pătrășcanu family that has, according to him, gone through the whole gamut of emotions from pride in Lucretiu’s academic achievements, embarrassment for his political views, feelings of uncomprehension and betrayal, worry for his fate and then for their own as the sins of one family member would affect them all.  
There is some family story that I hope is true: later in life my grandfather, now well established professionally, was able to help his old school friend, Patru with something significant, though I have no details. It would only have been right.

For my family it was Pătrășcanu Sr who was the hero that generously helped a poor boy when he needed it most.

`
    content = postText
	}


const summary = Post9Summary(lang)
return {
	content,
	linkId: summary.linkId,
	title: summary.cardTitle
}
}

export default Post9;
