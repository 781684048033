// Post1.js

import Post1Summary from "../summary/Post1Summary"

const Post1 = (lang) => {
	let content = ''
    if (lang === 'rom') {
		const romText = `
Sunt Doina și postez aici despre lucruri pe care le iubesc: amintiri, 
povești cu parfum de epocă despre stramosii mei si ce au lăsat ei în urmă sau despre obiecte vechi cu o încărcătură emotională deosebită. <br>
Sper că veți găsi ceva interesant pe aici. <bt>
Vă mulțumesc că ați citit.
`
		content = romText
	} else {

	const postText = `
I am Doina and I post here about things I love: my memories, stories of my Romanian ancestors and their legacies, special objects. 
This is mainly an ancestry blog but focused on stories rather than blood lines or timelines. 
I aim to be accurate as I capture people, worlds or customs from the past and to find a 
nugget of timeless truth in these stories. 
I hope you find something interesting here.
Thank you for reading.
	`
	content = postText
	}
	const summary = Post1Summary(lang)
	return {
		content,
		linkId: summary.linkId,
		title: summary.cardTitle
	}
 }
export default Post1;
