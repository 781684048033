


const Post5Summary = (lang) => {
	const isRom = lang === 'rom'

	
	return {
		lang,
		cardTitle: isRom? 'Bătăliile eroice din bucătarie': 'Epic battles in the kitchen', 
		cardText:  isRom ?  'Acesta e povestea unei persoane remarcabile din copilaria mea mica si toate lucrurile minunate, materiale sau nu, pe care mi le-a daruit.': 
		'This is the story of one of the most influential person of my early childhood and the all the lovely things I got from her', 
		photoPath: '/post5/collage5long.png', 
		photoPathShort: '/post5/collage5s.png', 
		linkId: 'battles'
	}

}

export default Post5Summary;
