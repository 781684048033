// Post4.js


const Post4Summary = (lang) => {
	const isRom = lang === 'rom'

	
	return {
		lang,
		cardTitle: isRom? 'Fața de masă': 'The tablecloth', 
		cardText:  isRom ?  'Acesta e povestea, si frumoasa dar si tragica, a stra-matusei Flora, moasa in Bucurestiul interbelic, a cărei creatie e acum la loc de cinste in casa mea.': 
		'This is the story, both inspiring and tragic of my great-great aunt Flora, midwife in Bucharest between the two world wars and how one of her creations take pride of place in my home.', 
		photoPath: '/post4/collage4l.png', 
		photoPathShort: '/post4/collage4s.png', 
		linkId: 'tablecloth'
	}

}

export default Post4Summary;
