const Post6Summary = (lang) => {
	const isRom = lang === 'rom'

	
	return {
		lang,
		cardTitle: isRom? 'Metafora accidentala': 'The accidental metaphor', 
		cardText:  isRom ?  'E mai mult decit se vede la prima vedere in aceasta fotografie din 1935.': 
		'There is more than meets the eye in this street photo from 1935.', 
		photoPath: '/post6/photolong.png', 
		photoPathShort: '/post6/photo_s.png', 
		linkId: 'metaphor'
	}

}

export default Post6Summary;
