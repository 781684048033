const Post1Summary = (lang) => {
	const isRom = lang === 'rom'
	const cardTextEng = `
	What this blog is all about? Fact or Fiction? What sort of treasure are we talking about?
	`
	const cardTextRom = `
	Ce fel de blog e asta? Despre ce fel de comori vorbim? Ce e adevarat din toate astea? De ce as citi asa ceva?
	`
	
	return {
		lang,
		cardTitle: isRom? 'Bine ati venit la lada mea de zestre virtuala': 'Welcome to my treasure box', 
		cardText:  isRom ?  cardTextRom: cardTextEng, 
		photoPath: '/post1/collage1long.png', 
		photoPathShort: '/post1/collage1s.png', 
		linkId: '#intro'
	}
 }
export default Post1Summary;
