const Post9Summary = (lang) => {
	const isRom = lang === 'rom'

	
	return {
		lang,
		cardTitle: isRom?  'Pe marginea istoriei' :'On the fringes',	
		cardText:  isRom ?  'Aceasta este povestea începuturilor foarte umile ale bunicului meu matern și a modului în care a avut norocul să cunoască și să primească ajutor de la o familie care a fost semnificativă, atît cultural cît și politic, în istoria României.': 
		'This is the story of the very humble beginnings of my maternal grandfather and how he had the luck to cross paths and get help from a family that was culturally and politically significant for Romanian history.', 
		photoPath: '/post9/collage9long.png', 
		photoPathShort: '/post9/collage9s.png', 
		linkId: 'fringe'
	}

}

export default Post9Summary;
