
import { Card } from "react-bootstrap";
import { Dimensions } from 'react-native';
import { useState } from "react";

import PostCommentsComponent from "../PostCommentsComponent";

const PostSummary = ({ lang, cardTitle, cardText, photoPathLong, photoPathShort, linkId, likes, comments, onLikesUpdated, onCommentsUpdated }) => {
	const windowDimensions = Dimensions.get('window');
	//const screenDimensions = Dimensions.get('screen');
	// to do compute HREF
	let width
	let height
	let isLarge = true
	if (windowDimensions.width < 800) {
		width = 10
		height = 380
		isLarge = false
	} else if (windowDimensions.width < 1200) {
		width = 20
		height = 250
	}else {
		width = 25
		height = 250
	}
	return (
		<Card>
			<Card.Img
				variant="top"
				src={isLarge ? photoPathLong : photoPathShort}
				width={width}
				
			/>
			<Card.Body>
				<Card.Title>{cardTitle}</Card.Title>
				<Card.Text>
					{cardText}
				</Card.Text>
				<a href={`${linkId}?lang=${lang}`} className="btn btn-primary">{lang === 'rom' ? 'Continuarea' : 'Read More'}</a>
				<PostCommentsComponent
				    lang={lang}
					postId={linkId}
					key={`post${linkId}`}
					likes={likes} 
					comments={comments}
					onLikesUpdated={onLikesUpdated}
					onCommentsUpdated={onCommentsUpdated}
				/>
			</Card.Body>
		</Card>
	);
};

export default PostSummary;
