const Post7Summary = (lang) => {
	const isRom = lang === 'rom'

	
	return {
		lang,
		cardTitle: isRom?  'Ceva de privit' :'Something to look at',	
		cardText:  isRom ?  'Ca si copil, a trebuit sa stau cuminte si sa ma uit pe pereti cu diverse ocazii. Acestea sunt circumstantele si obiectele mai interesante la care m-am uitat timp indelungat.': 
		'As a child, I spent time staring at walls for reasons that will be explained. These are the more interesting and beautiful things that I spent a long time looking at.', 
		photoPath: '/post8/collage8long.png', 
		photoPathShort: '/post8/collage8s.png', 
		linkId: 'look'
	}

}

export default Post7Summary;
