const Post10Summary = (lang) => {
	const isRom = lang === 'rom'

	
	return {
		lang,
		cardTitle: isRom?  'Vocatie' :'The pursuit of excellence',	
		cardText:  isRom ?  'Acesta este o poveste despre tatăl meu, Alexandru și despre ceea ce am descoperit despre el cu mult timp după ce l-am pierdut. Cred nici el nu și-a dat seama de asta despre sine insusi.': 
		'Here is a story about my dad, Alexandru and what I have discovered about him long time after he was gone and what, I am sure, he did not realise himself.', 
		photoPath: '/post10/post10long.png', 
		photoPathShort: '/post10/post10s.png', 
		linkId: 'excellence'
	}

}

export default Post10Summary;
