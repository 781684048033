
const Post3Summary = (lang) => {
     const isRom = lang === 'rom'
	const cardTextEng = `
This entry contains fragments of letters of timeless and beautiful wisdom, written by my great-gradmother Constanța for her teenager granddaughter.
	`
	const cardTextRom = `
Acesta postare contine fragmente pline de intelepciune si frumusete, scrise de strabunica mea, Constanța adresate nepoatei sale aflate la varsta adolescenței.
	`
	
	return {
		lang,
		cardTitle: isRom? 'Scrisori de demult': 'Letters, learning, love', 
		cardText:  isRom ?  cardTextRom: cardTextEng, 
		photoPath: '/post3/collage3long.png', 
		photoPathShort: '/post3/collage3s.png', 
		linkId: 'letters'
	}
 }

export default Post3Summary;
