import React from "react";
import PostComponent from "./PostComponent";
import { Container } from 'react-bootstrap';


const PostPage = ({ urlSearchParameters, lang, linkId, text, leftLinkId, leftLinkText, rightLinkId, rightLinkText, likes, comments, onLikesUpdated, onCommentsUpdated }) => {
  
    return (
        <>
            <Container>
                <br/>
                <br/>
                <PostComponent
                    lang={lang}
                    linkId={linkId}
                    text={text}
                    leftLinkId={leftLinkId}
                    leftLinkText={leftLinkText}
                    rightLinkId={rightLinkId}
                    rightLinkText={rightLinkText}
                    likes={likes}
                    comments={comments}
                    onLikesUpdated={onLikesUpdated}
                    onCommentsUpdated={onCommentsUpdated}
                    
                />
            </Container>
        </>
    )
}


export default PostPage;